import React, { useState, Fragment, useRef, useEffect } from "react";
import "./Signup.scss";
import { useForm } from "react-hook-form";
import logo from "../../assets/images/logo.svg";

import Input from "../../components/Input/Input";
import { ValidationService } from "../../utils/Validation";
import Btn from "../../components/Button/Button";
import Phone from "../../components/phone-picker/phone-picker";
import useApiService from "../../services/api.service";
import { useTranslation } from "react-i18next";
import useLoader from "../../hooks/useLoader";
import SigninEndpoints from "./Signup.endpoints";
import useToast from "../../components/Toast/hooks/useToast";
import { useHistory, useLocation } from "react-router-dom";
import useLocalStorage from "../../utils/localStorage";
import { useAppContext } from "../../AppContext/App.context";
import signup1 from "../../assets/images/signup1.png";
import { Grid, IconButton, Typography } from "@material-ui/core";
import { themeColor } from "../../constants/themeColor";
import rebrand from "../../assets/images/rebranding_image.png";
import { Visibility, VisibilityOff } from "@material-ui/icons";

const Register = () => {
  const { t, i18n } = useTranslation(["Signup", "Common"]);
  const { register, handleSubmit, errors, watch } = useForm();
  const loader: any = useLoader();
  const ApiService = useApiService();
  const customData = useLocalStorage.getItem("CustomData");
  const [configSignupText, setConfigSignupText]: any = useState("");
  const toaster = useToast();
  const history = useHistory();
  const dialCode: any = useRef<any>();
  const countryCode: any = useRef();
  const location = useLocation();
  const stateValue: any = location.state;
  const [appData]: any = useAppContext();
  const [password, setPassword] = useState("");
  const { password: confirmPassword } = watch();

  const [showPassword, setShowPassword] = useState({
    showPass: false,
    showConfirmPass: false,
  });
  const [phoneValue, setPhoneValue] = useState(stateValue?.phone_number || "");
  const [phoneError, setPhoneError] = useState(false);
  const handleClickShowPassword = (showPass: any) => {
    if (showPass) {
      setShowPassword({ ...showPassword, showPass: !showPassword.showPass });
    } else {
      setShowPassword({
        ...showPassword,
        showConfirmPass: !showPassword.showConfirmPass,
      });
    }
  };
  const [configImage, setConfigImage]: any = useState("");

  useEffect(() => {
    if (Object.keys(appData?.formSettings).length < 1) {
      setConfigImage(signup1);
      setConfigSignupText(t("Common:Hello! Sign up to get started"));
    } else {
      if (
        appData?.formSettings &&
        appData?.formSettings?.colors &&
        Object.keys(appData?.formSettings?.colors).length > 0
      ) {
        imageConfig();
      } else {
        setConfigSignupText(t("Common:Hello! Sign up to get started"));
        setConfigImage(signup1);
      }
    }
  }, [appData.formSettings]);

  const imageConfig = () => {
    if (
      appData.formSettings &&
      appData.formSettings.colors &&
      appData.formSettings.colors.login_bg_image
    ) {
      setConfigImage(appData.formSettings.colors.login_bg_image);
    } else {
      setConfigImage(signup1);
    }
    if (
      appData.formSettings &&
      appData.formSettings.colors &&
      appData.formSettings.colors.signup_heading
    ) {
      setConfigSignupText(appData.formSettings.colors.signup_heading);
    }
  };

  const setBackgroundImage = () => {
    return `url(${configImage})`;
  };

  const [preSavedCountryCode, updatePreSavedCountryCode]: any = useState();
  const obj = {
    name: {
      required: ValidationService.requiredValidator(),
    },
    email: {
      required: ValidationService.requiredValidator(),
      pattern: ValidationService.emailValidator(),
    },
    phone: {
      required: ValidationService.requiredValidator(),
    },
    password: {
      required: ValidationService.requiredValidator(),
      pattern: ValidationService.passwordValidator(),
    },
    confirm_password: {
      required: "Confirm Password is required",
      validate: (value: string) =>
        value === password || "Passwords do not match",
    },
  };

  const fetchDialCode = (dialcode: any, countrycode: any) => {
    dialCode.current = "+" + dialcode;
    countryCode.current = countrycode.toUpperCase();
  };

  // Submit Form
  const submitSignupForm = (data: any) => {
    if (!String(phoneValue)?.length) {
      setPhoneError(true);
      return;
    }
    if (!data.name.trim()) {
      toaster.addToast({
        message: "Name is invalid",
        timeout: 3000,
        type: "error",
      });
      return;
    }
    const request: any = {
      country_code: dialCode.current,
      phone_number: data?.phone || phoneValue,
      app_version: "100.1",
      device_details: "1",
      name: data.name.trim(),
      email: data.email,
      password: data.password,
    };
    const headers = {
      reference_id: useLocalStorage.getItem("referenceId"),
    };
    const reqData = {
      reqBody: request,
      headers: headers,
    };
    loader.showLoader();
    return ApiService.post(SigninEndpoints.signin(reqData))
      .then(async (res: any) => {
        loader.hideLoader();
        console.log(res);

        if (res.message === "success") {
          // useLocalStorage.setItem("loginData", res.data);
          // useLocalStorage.setItem("userAccessToken", res.data.access_token);
          if (appData.loginData) {
            history.push(
              `/checkotp/${dialCode.current}/${data?.phone || phoneValue}`
            );
          }
        } else {
        }
      })
      .catch((err) => {
        console.log(err);
        loader.hideLoader();
        toaster.addToast({
          message: err.message,
          timeout: 3000,
          type: "error",
        });
      });
  };

  const phoneChangeHandler = (e: any) => {
    setPhoneValue(e.target.value);
    setPhoneError(false);
  };

  const formSubmitHandler = () => {
    if (!String(phoneValue)?.length) {
      setPhoneError(true);
      return;
    }
  };

  return (
    <Grid container direction="row" className="SignUp">
      <Grid
        item
        xs={6}
        style={{
          borderRight: "1px solid lightgrey",
          backgroundSize: appData?.formSettings?.colors
            ? "100% auto"
            : "contain",
          backgroundImage: setBackgroundImage(),
          backgroundRepeat: appData?.formSettings?.colors
            ? "no-repeat"
            : "repeat",
          minHeight: appData?.formSettings?.colors ? "100vh" : "100vh",
        }}
        className={"Login__Side-Image"}
      >
        {!appData?.formSettings?.colors && (
          <Grid
            container
            alignItems={"center"}
            direction="column"
            style={{ marginTop: "60px" }}
          >
            <Grid item xs={10}>
              <Typography
                style={{
                  fontSize: "36px",
                  fontWeight: "bold",
                  color: `${themeColor.whiteColor}`,
                }}
              >
                Amazing Delivery Experience
              </Typography>
            </Grid>

            <Grid>
              <Typography
                style={{
                  fontSize: "36px",
                  fontWeight: "bold",
                  color: `${themeColor.whiteColor}`,
                }}
              >
                For Your Customers And
              </Typography>
            </Grid>
            <Grid>
              <Typography
                style={{
                  fontSize: "36px",
                  fontWeight: "bold",
                  color: `${themeColor.whiteColor}`,
                }}
              >
                Your Teams
              </Typography>
            </Grid>
          </Grid>
        )}
      </Grid>

      <Grid item xs={6}>
        <div style={{ overflow: "auto" }}>
          <div
            className={"logo-image-signup"}
            // style={{ margin: "50px 0px", textAlign: "center" }}
          >
            <img
              // className="align-self-center Login__logo"
              src={
                customData && customData.login_icon
                  ? customData.login_icon
                  : logo
              }
              alt=""
              width={210}
            ></img>
          </div>

          <div className="Signup__body">
            <Typography className={"Signup__trial-heading-text"}>
              {" "}
              {configSignupText
                ? configSignupText
                : t("Common:Hello! Sign up to get started")}
            </Typography>
            <div className="Signup__card">
              <Fragment>
                <form onSubmit={handleSubmit(submitSignupForm)}>
                  <Input
                    type="text"
                    id="name"
                    name="name"
                    refValue={register(obj.name)}
                    errors={errors}
                    placeholder="Enter Your Name"
                    label={t("Common:Name") + "*"}
                  ></Input>

                  <Input
                    type="email"
                    id="email"
                    name="email"
                    refValue={register(obj.email)}
                    errors={errors}
                    placeholder="Enter Your Email Address"
                    label={t("Common:Email Address") + "*"}
                  ></Input>
                  <div style={{ paddingBottom: "0.25rem" }}>
                    <Phone
                      hideLabel={true}
                      showPhone={true}
                      setDialCode={fetchDialCode}
                      name="phone"
                      label={t("Common:Mobile Number")}
                      placeholder={t("Common:Mobile Number") + "*"}
                      required={true}
                      defaultPhone={preSavedCountryCode}
                      errors={
                        phoneError
                          ? {
                              phone: {
                                value: true,
                                message: t(
                                  "Validation:This field is required."
                                ),
                              },
                            }
                          : ""
                      }
                      userCountry={
                        stateValue?.country_code?.split("+")?.[1] || ""
                      }
                      submitRequired={true}
                      value={phoneValue || ""}
                      onchange={phoneChangeHandler}
                      // refValue={register(obj.phone)}
                    ></Phone>
                  </div>
                  <Input
                    type={showPassword.showPass ? "text" : "password"}
                    id="password"
                    name="password"
                    onchange={(e: any) => setPassword(e.target.value)}
                    refValue={register(obj.password)}
                    errors={errors}
                    placeholder="Enter Your Password"
                    label={t("Common:Password") + "*"}
                    // InputProps={{
                    //   endAdornment: (
                    //     <IconButton
                    //       aria-label="toggle password visibility"
                    //       onClick={() => handleClickShowPassword(true)}
                    //     >
                    //       {!showPassword.showPass ? (
                    //         <Visibility />
                    //       ) : (
                    //         <VisibilityOff />
                    //       )}
                    //     </IconButton>
                    //   ),
                    // }}
                  ></Input>
                  <Input
                    type={showPassword.showConfirmPass ? "text" : "password"}
                    id="confirm_password"
                    name="confirm_password"
                    refValue={register(obj.confirm_password)}
                    errors={errors}
                    placeholder="Enter Your Confirm Password"
                    label={t("Common:Confirm Password") + "*"}
                    InputProps={{
                      endAdornment: (
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => handleClickShowPassword(false)}
                        >
                          {!showPassword.showConfirmPass ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      ),
                    }}
                  ></Input>
                  <div
                    style={{
                      textAlign: "center",
                      marginTop: "-10px",
                      // marginBottom: "-16px",
                    }}
                  >
                    <Btn
                      onClick={formSubmitHandler}
                      type={"Submit"}
                      btnText={t("Common:Sign Up")}
                      className={"Signup__btn"}
                    />
                  </div>
                </form>
              </Fragment>
            </div>
            <div
              className="Signup__login my-4"
              onClick={() => history.push("/login")}
            >
              {t("Common:Already have an Account")}
              {`?`} {t("Common:Sign In")}
            </div>
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

export default Register;
