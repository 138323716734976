import {
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import Btn from "../../components/Button/Button";
import Input from "../../components/Input/Input";
import { useTranslation } from "react-i18next";
import { Visibility, VisibilityOff, ArrowBack } from "@material-ui/icons";

const PasswordLoginComponent = (props: any) => {
  const {
    isPasswordSelected,
    setIsPasswordSelected,
    userInfo,
    passwordChangeHandler,
    handleSubmit,
    submitLoginForm,
    handleSubmitForgotPassword,
    handlePasswordSubmit,
    errorsPassword,
    inputFocusPassword,
    showErrormsgPass,
    setForgetPassword,
    setShowPasswordForm,
  } = props;
  const { t, i18n } = useTranslation(["Login", "Common", "Signup"]);
  const [showPassword, setShowPassword] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  useEffect(() => {
    return () => {
      passwordChangeHandler(null);
    };
  }, []);

  const loginRadioTypeHandler = (value: string) => {
    setIsPasswordSelected(value);
    passwordChangeHandler(null);
  };

  const passwordFormSubmitHandler = (e: any) => {
    e.preventDefault();
    if (!userInfo?.password?.length) {
      setPasswordError(true);
      return;
    }
    handlePasswordSubmit();
  };

  return (
    <Fragment>
      <div
        style={{
          textAlign: "center",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {/* <span
          onClick={() => setShowPasswordForm(false)}
          style={{ cursor: "pointer" }}
        >
          <ArrowBack />
        </span> */}
        <Typography className={"Login__trial-heading-text"}>
          {" "}
          {t("Common:Please choose your preferred login method")}
        </Typography>
        <span></span>
      </div>
      <FormControl>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue="password"
          value={isPasswordSelected}
          name="radio-buttons-group"
          onChange={(e) => loginRadioTypeHandler(e.target.value)}
          style={{ flexDirection: "row", justifyContent: "space-around" }}
        >
          <FormControlLabel
            value="otp"
            control={<Radio color="primary" />}
            label="Via OTP"
          />
          <FormControlLabel
            value="password"
            control={<Radio color="primary" />}
            label="Via Password"
          />
        </RadioGroup>
      </FormControl>
      {isPasswordSelected === "password" ? (
        // <form
        //   style={{ paddingTop: "0.5rem" }}
        //    onSubmit={handlePasswordSubmit}
        // >
        <>
          <div>
            <Input
              type={showPassword ? "text" : "password"}
              id="password"
              name="password"
              // value={userInfo.password}
              errors={
                passwordError && {
                  password: {
                    value: true,
                    message: t("Validation:This field is required."),
                  },
                }
              }
              required={true}
              inputFocus={inputFocusPassword}
              placeholder="Enter Your Password"
              // showErrormsg={showErrormsgPass}
              label={t("Common:Password")}
              onchange={(e: { target: { value: any } }) => {
                passwordChangeHandler(e.target.value);
                setPasswordError(false);
              }}
              InputProps={{
                endAdornment: (
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {!showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                ),
              }}
            />
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <a
                style={{ color: "blue" }}
                href="#"
                onClick={() => setForgetPassword(true)}
              >
                {t("Login:Forgot Password") || ""}
              </a>
            </div>
          </div>
          <Grid
            container
            justifyContent="center"
            style={{ marginTop: "-10px", gap: "1.5rem" }}
          >
            <Btn
              type={"Submit"}
              variant={"outlined"}
              onClick={() => setShowPasswordForm(false)}
              btnText={t("Signup:Back")}
              className={"Login__btn Login_back_btn"}
            />
            <Btn
              type={"Submit"}
              btnText={t("Signup:Submit")}
              className={"Login__btn"}
              onClick={passwordFormSubmitHandler}
            />
          </Grid>
        </>
      ) : (
        // </form>
        <form onSubmit={handleSubmit(submitLoginForm)}>
          <Grid
            container
            justifyContent="center"
            style={{ marginTop: "-10px", gap: "1.5rem" }}
          >
            <Btn
              type={"Submit"}
              variant={"outlined"}
              onClick={() => setShowPasswordForm(false)}
              btnText={t("Signup:Back")}
              className={"Login__btn Login_back_btn"}
            />

            <Btn
              type={"Submit"}
              btnText={t("Signup:Get OTP")}
              className={"Login__btn"}
            />
          </Grid>
        </form>
      )}
    </Fragment>
  );
};
export default PasswordLoginComponent;
